import Icons from 'Icons';
import WorkStepsProgress from 'features/sales/components/WorkStepsProgress/WorkStepsProgress';
import WorkItemStepStatus from 'features/workOrders/enums/WorkItemStepStatus';
import WorkItemStepUiState, {
    computeWorkItemStepUiState,
} from 'features/workOrders/enums/WorkItemStepUiState';
import { WorkItemStepBasic } from 'features/workOrders/models/WorkItemStepBasic';
import { WorkOrderItemDetail } from 'features/workOrders/models/WorkOrderItemDetail';
import { useDialogManager } from 'providers/DialogManager';
import React, { useCallback } from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import WorkItemStepHistoryModal from '../WorkItemStepHistoryModal/WorkItemStepHistoryModal';
import WorkItemStepStatusBadge from '../WorkItemStepStatusBadge/WorkItemStepStatusBadge';
import './WorkItemStepList.scss';

const UI_STATE_ORDER = [
    WorkItemStepUiState.InProgress,
    WorkItemStepUiState.OnHold,
    WorkItemStepUiState.Ready,
    WorkItemStepUiState.ComingUp,
    WorkItemStepUiState.Completed,
    WorkItemStepUiState.Skipped,
];
export default function WorkItemStepList({
    workItem,
    className,
}: {
    workItem: WorkOrderItemDetail;
    className?: string;
}) {
    const dialogManager = useDialogManager();

    const showHistory = useCallback(
        (step: WorkItemStepBasic) => {
            dialogManager.custom(WorkItemStepHistoryModal, {
                model: step,
            });
        },
        [dialogManager],
    );

    const steps = [...workItem.context.workOrderItemSteps];
    steps.sort((a, b) => {
        // primary sort by ui state using predefined order
        const uiStateA = computeWorkItemStepUiState(a.status, a.context.canTransition);
        const uiStateB = computeWorkItemStepUiState(b.status, b.context.canTransition);
        if (uiStateA !== uiStateB) {
            return UI_STATE_ORDER.indexOf(uiStateA) - UI_STATE_ORDER.indexOf(uiStateB);
        }
        // secondary sort by last transition timestamp - most recent first
        if (a.lastTransitionTimestamp !== b.lastTransitionTimestamp) {
            return (a.lastTransitionTimestamp ?? '') > (b.lastTransitionTimestamp ?? '') ? -1 : 1;
        }
        // tertiary sort by sort order
        if (a.sortOrder !== b.sortOrder) {
            return a.sortOrder - b.sortOrder;
        }
        // last ditch - sort by id
        return a.id > b.id ? 1 : -1;
    });

    const stepCount = steps?.length ?? 0;
    const completeCount =
        steps?.filter(s =>
            [
                WorkItemStepStatus.Completed,
                WorkItemStepStatus.CompletedLocked,
                WorkItemStepStatus.Skipped,
                WorkItemStepStatus.SkippedLocked,
            ].includes(s.status),
        ).length ?? 0;

    const completePercent = stepCount ? Math.round((completeCount / stepCount) * 100) : 0;

    if (!steps) {
        return null;
    }

    return (
        <div className={coalesceClassNames('WorkItemStepList', className)}>
            <div className="WorkItemStepList__Progress">
                <div className="WorkItemStepList__Progress__Counts">
                    <strong>
                        {stepCount} {stepCount === 1 ? 'step' : 'steps'}
                    </strong>
                    <div>
                        {completeCount} complete ({completePercent}%)
                    </div>
                </div>
                <div className="WorkItemStepList__Progress__Bar">
                    <WorkStepsProgress steps={steps} />
                </div>
            </div>

            <div className="WorkItemStepList__Steps">
                {steps?.map(step => {
                    const uiState = computeWorkItemStepUiState(
                        step.status,
                        step.context.canTransition,
                    );
                    return (
                        <a
                            key={step.id}
                            className="WorkItemStepList__Step"
                            onClick={() => showHistory(step)}
                        >
                            <div className="step-name">{step.context.workflowStep.name}</div>
                            <div className="workstation">
                                {step.context.workstationLatest?.name}
                            </div>
                            <WorkItemStepStatusBadge
                                uiState={uiState}
                                lastTransitionReason={step.lastTransitionReason ?? null}
                                badgeType="badge"
                            />
                            <Icons.ChevronRight className="chevron" />
                        </a>
                    );
                })}
            </div>
        </div>
    );
}
