import Icons from 'Icons';
import MyButton, { MyButtonLink } from 'components/MyButton/MyButton';
import PageHeader from 'components/PageHeader/PageHeader';
import DocumentsTable from 'features/documents/components/DocumentsTable/DocumentsTable';
import documentsApi from 'features/documents/documents.api';
import React from 'react';
import { Outlet, useSearchParams } from 'react-router-dom';
import { usePageTitle } from 'utils/locationHelpers';

export default function DocumentsPage() {
    usePageTitle('Documents');
    const [searchParams] = useSearchParams();
    const query = documentsApi.useDocumentListQuery();
    return (
        <>
            <PageHeader title="Documents">
                <MyButton
                    buttonType="Accent"
                    size="medium"
                    label="Upload documents"
                    IconLeft={Icons.Upload}
                    href={`upload?${searchParams}`}
                    LinkComponent={MyButtonLink}
                />
            </PageHeader>
            <DocumentsTable
                data={query.data}
                isLoading={query.isLoading}
                isError={query.isError}
                onRefresh={query.refetch}
                isRefreshing={query.isFetching}
                rowLinkTo={doc => `${doc.id}?${searchParams}`}
            />

            <Outlet />
        </>
    );
}
