import Icons from 'Icons';
import DataTable, { ColumnBuilder } from 'components/DataTable/DataTable';
import MyButton from 'components/MyButton/MyButton';
import DocumentDetailModal from 'features/documents/components/DocumentDetailModal/DocumentDetailModal';
import DocumentFileIcon from 'features/documents/components/DocumentFileIcon/DocumentFileIcon';
import DocumentUploadModal from 'features/documents/components/DocumentUploadModal/DocumentUploadModal';
import { DocumentFriendlyTypeDisplay } from 'features/documents/enums/DocumentFriendlyType';
import { DocumentFile } from 'features/documents/models/DocumentFile';
import { DocumentUploadResultDocument } from 'features/documents/models/DocumentUploadResult';
import { SalesOrder } from 'features/sales/models/SalesOrder';
import salesApi from 'features/sales/sales.api';
import React, { useCallback, useMemo, useState } from 'react';
import { formatDateTimeRelative } from 'utils/dateHelpers';
import './SalesOrderAttachmentsTab.scss';

const COLUMNS = ColumnBuilder<DocumentFile>()
    .column({
        key: 'name',
        label: 'Name',
        isSortable: true,
        getValue: item => item.name,
        renderValue: (val, doc) => (
            <>
                <DocumentFileIcon type={doc.type} />
                &nbsp;{val}
            </>
        ),
    })
    .column({
        key: 'type',
        label: 'Type',
        isSortable: true,
        getValue: item => item.type,
        renderValue: val => DocumentFriendlyTypeDisplay.display(val),
    })
    .column({
        key: 'owner',
        label: 'Owner',
        isSortable: true,
        getValue: item => (item.isDealerOwned ? 'Dealer' : 'Me'),
    })
    .column({
        key: 'created_at',
        label: 'Created',
        isSortable: true,
        defaultSort: 'DESC',
        getValue: item => item.created_at,
        renderValue: val => formatDateTimeRelative(val),
    })
    .build();

export default function SalesOrderAttachmentsTab({ order }: { order: SalesOrder }) {
    const docsQuery = salesApi.useSalesOrderDocumentsQuery(order.legacyId);
    const dealerDocsQuery = salesApi.useSalesOrderDealerDocumentsQuery(order.legacyId);

    const [showUpload, setShowUpload] = useState(false);
    const [attachMutation] = salesApi.useSalesOrderDocumentAttachMutation();
    const [archiveMutation] = salesApi.useSalesOrderDocumentArchiveMutation();

    /** After the document has been uploaded, attach it to the sales order */
    const attachUploadedDocument = useCallback(
        (document: DocumentUploadResultDocument) => {
            return attachMutation({
                document,
                manufacturerOrderId: order.legacyId,
            }).unwrap();
        },
        [attachMutation, order.legacyId],
    );

    const handleArchive = useCallback(
        async (documentId: number) => {
            return archiveMutation({
                manufacturerOrderId: order.legacyId,
                documentId,
            }).unwrap();
        },
        [archiveMutation, order.legacyId],
    );

    const allAttachments = useMemo(
        () => [...(docsQuery.data?.data ?? []), ...(dealerDocsQuery.data?.data ?? [])],
        [docsQuery.data?.data, dealerDocsQuery.data?.data],
    );

    const [detailDocId, setDetailDocId] = useState<number>();
    const detailModel = useMemo(
        () => detailDocId && allAttachments.find(d => d.id === detailDocId),
        [allAttachments, detailDocId],
    );

    return (
        <div className="SalesOrderAttachmentsTab">
            {/* Manufacturer docs */}
            <h2 className="SalesOrderAttachmentsTab__Heading">
                Attachments
                <MyButton
                    buttonType="Primary"
                    size="medium"
                    label="Upload"
                    IconLeft={Icons.Upload}
                    onClick={() => setShowUpload(true)}
                />
            </h2>
            <div className="SalesOrderAttachmentsTab__TableContainer">
                <DataTable
                    data={allAttachments}
                    columns={COLUMNS}
                    isLoading={docsQuery.isLoading}
                    isError={docsQuery.isError}
                    onRefresh={docsQuery.refetch}
                    isRefreshing={docsQuery.isFetching}
                    onRowClick={doc => setDetailDocId(doc.id)}
                    emptyState="No attachments"
                    zebra
                />
            </div>

            {showUpload && (
                <DocumentUploadModal
                    showBrand={false}
                    directUpload={true}
                    afterUpload={attachUploadedDocument}
                    close={() => setShowUpload(false)}
                />
            )}

            {detailModel && (
                <DocumentDetailModal
                    model={detailModel}
                    showBrand={false}
                    onArchive={handleArchive}
                    close={() => setDetailDocId(undefined)}
                />
            )}
        </div>
    );
}
