import { skipToken } from '@reduxjs/toolkit/dist/query';
import Icons from 'Icons';
import DataTable, { ColumnBuilder } from 'components/DataTable/DataTable';
import MessagePanel from 'components/MessagePanel/MessagePanel';
import MyButton from 'components/MyButton/MyButton';
import MyMenuButton, { MyMenuButtonItem } from 'components/MyMenuButton/MyMenuButton';
import MyMenuKebabButton from 'components/MyMenuKebabButton/MyMenuKebabButton';
import MyModal from 'components/MyModal/MyModal';
import MyTabs, { TabBuilder } from 'components/MyTabs/MyTabs';
import PageHeader from 'components/PageHeader/PageHeader';
import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import { SafeHTML } from 'components/SafeHtml/SafeHtml';
import Env from 'config/Env';
import FeatureFlag from 'enums/FeatureFlag';
import SalesOrderCoreStatus from 'features/sales/enums/SalesOrderCoreStatus';
import { SalesItem } from 'features/sales/models/SalesItem';
import { getCustomerDisplayName, SalesOrder } from 'features/sales/models/SalesOrder';
import { SalesOrderDetail } from 'features/sales/models/SalesOrderDetail';
import { SalesOrderWorkItem } from 'features/sales/models/SalesOrderWorkItem';
import salesApi from 'features/sales/sales.api';
import useUrlQueryState from 'hooks/useUrlQueryState';
import { useDialogManager } from 'providers/DialogManager';
import React, { useCallback, useMemo, useState } from 'react';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import coalesceClassNames from 'utils/coalesceClassNames';
import { formatDateRelative, formatDateTimeRelative } from 'utils/dateHelpers';
import { formatCurrency } from 'utils/helpers';
import { useFeatureFlagEnabled } from 'utils/posthogHelpers';
import LabelPrintDialog, { LabelPrintDialogResult } from '../LabelPrintDialog/LabelPrintDialog';
import SalesItemBulkAssignStatusDialog from '../SalesItemBulkAssignStatusDialog/SalesItemBulkAssignStatusDialog';
import SalesItemStatusBadge from '../SalesItemStatusBadge/SalesItemStatusBadge';
import SalesOrderCancelDialog from '../SalesOrderCancelDialog/SalesOrderCancelDialog';
import SalesOrderEditModal from '../SalesOrderEditModal/SalesOrderEditModal';
import SalesOrderSplitDialog from '../SalesOrderSplitDialog/SalesOrderSplitDialog';
import SalesOrderStatusBadge from '../SalesOrderStatusBadge/SalesOrderStatusBadge';
import SalesOrderWorksheetsDialog from '../SalesOrderWorksheetsDialog/SalesOrderWorksheetsDialog';
import WorkItemsProgress from '../WorkItemsProgress/WorkItemsProgress';
import SalesOrderAccountingTab from './SalesOrderAccountingTab';
import SalesOrderAttachmentsTab from './SalesOrderAttachmentsTab';
import './SalesOrderDetailModal.scss';
import SalesOrderHistoryTab from './SalesOrderHistoryTab';

export default function SalesOrderDetailModal({
    model,
    isLoading,
    isError,
    close,
}: {
    model?: SalesOrderDetail;
    isLoading?: boolean;
    isError?: boolean;
    close?: () => void;
}) {
    const [unarchiveMutation] = salesApi.useSalesOrderUnarchiveMutation();
    const [resyncMutation] = salesApi.useSalesOrderResyncProductDataMutation();
    const [archiveMutation] = salesApi.useSalesOrderArchiveMutation();

    const dialogManager = useDialogManager();

    const edit = useCallback(() => {
        dialogManager.custom(SalesOrderEditModal, {
            model,
        });
    }, [dialogManager, model]);

    const resyncProductData = useCallback(async () => {
        const confirm = await dialogManager.confirm({
            title: 'Resync Product Data',
            message: (
                <>
                    This will pull the latest product configuration and update the order.
                    <br />
                    <br />
                    <em>This should only be done if requested by tech support.</em>
                    <br />
                    Are you sure?
                </>
            ),
            acceptLabel: 'Yes, resync now',
            acceptButtonType: 'Danger',
        });

        if (confirm && model) {
            try {
                await dialogManager.showLoadingWhile(resyncMutation(model).unwrap());
                dialogManager.alert({
                    message: 'Resync completed ✅',
                });
            } catch (e) {
                dialogManager.alert({
                    title: 'Resync failed ❌',
                    message:
                        'There was an error resyncing the order. Please contact technical support for further assistance.',
                });
            }
        }
    }, [resyncMutation, dialogManager, model]);

    const cancel = useCallback(async () => {
        if (model) {
            await dialogManager.custom(SalesOrderCancelDialog, {
                order: model,
            });
        }
    }, [dialogManager, model]);

    const archive = useCallback(async () => {
        const confirm = await dialogManager.confirm({
            title: 'Archive Sales Order',
            message: 'Are you sure you want to archive this order?',
            acceptLabel: 'Yes, archive now',
            acceptButtonType: 'Danger',
        });

        if (confirm && model) {
            await dialogManager.showLoadingWhile(archiveMutation(model));
            close?.();
        }
    }, [archiveMutation, close, dialogManager, model]);

    const unarchive = useCallback(async () => {
        const confirm = await dialogManager.confirm({
            title: 'Unarchive Sales Order',
            message: 'Are you sure you want to unarchive this order?',
            acceptLabel: 'Yes, Unarchive now',
            acceptButtonType: 'Primary',
        });

        if (confirm && model) {
            await dialogManager.showLoadingWhile(unarchiveMutation(model));
            close?.();
        }
    }, [close, dialogManager, model, unarchiveMutation]);

    function ArchivedBadge() {
        return <div className="SalesOrderDetailModal__ArchivedBadge">Archived</div>;
    }

    /** Accounting tab only shown if feature flag is on */
    const isAccountingEnabled = !!useFeatureFlagEnabled(FeatureFlag.Accounting);

    /** get the document counts to display in the documents tab */
    const docsQuery = salesApi.useSalesOrderDocumentsQuery(model?.legacyId ?? skipToken);
    const dealerDocsQuery = salesApi.useSalesOrderDealerDocumentsQuery(
        model?.legacyId ?? skipToken,
    );

    const documentCount = useMemo(() => {
        if (docsQuery.data && dealerDocsQuery.data) {
            return docsQuery.data.total + dealerDocsQuery.data.total;
        }
        return undefined;
    }, [dealerDocsQuery.data, docsQuery.data]);

    return (
        <MyModal
            className="SalesOrderDetailModal"
            close={close}
            isLoading={isLoading}
            isError={isError}
            fullHeight={true}
            mobileTitle="Sales"
            header={
                <PageHeader
                    className="SalesOrderDetailModal__PageHeader"
                    title="Sales Order"
                    titleContext={model?.manufacturerReference}
                >
                    <div className="SalesOrderDetailModal__PageHeader__Actions">
                        <MyButton
                            buttonType="Accent"
                            IconLeft={Icons.Edit}
                            label="Edit"
                            onClick={edit}
                        />
                        <MyMenuKebabButton
                            menuItems={useMemo(
                                (): (false | MyMenuButtonItem)[] =>
                                    !model?.context.isArchived
                                        ? [
                                              {
                                                  label: 'Resync product data',
                                                  IconLeft: Icons.Refresh,
                                                  onClick: resyncProductData,
                                              },
                                              model?.context.status.coreStatus !==
                                                  SalesOrderCoreStatus.Cancelled && {
                                                  label: 'Cancel order',
                                                  IconLeft: Icons.CancelOrder,
                                                  onClick: cancel,
                                              },
                                              {
                                                  label: 'Archive',
                                                  IconLeft: Icons.Archive,
                                                  onClick: archive,
                                              },
                                          ]
                                        : [
                                              {
                                                  label: 'Unarchive',
                                                  IconLeft: Icons.Undo,
                                                  onClick: unarchive,
                                              },
                                          ],
                                [
                                    archive,
                                    cancel,
                                    model?.context.isArchived,
                                    model?.context.status.coreStatus,
                                    resyncProductData,
                                    unarchive,
                                ],
                            )}
                        />
                    </div>
                </PageHeader>
            }
        >
            {model && (
                <>
                    {model?.context.status.coreStatus === SalesOrderCoreStatus.Cancelled && (
                        <MessagePanel
                            className="SalesOrderDetailModal__CancelledMessage"
                            messageType="warning"
                        >
                            This order has been cancelled
                        </MessagePanel>
                    )}
                    {/* Group props */}
                    <div className="SalesOrderDetailModal__Details">
                        <Sidebar model={model} />

                        <PropertyContainer layout="table">
                            {/* Sales Order props */}
                            <PropertyDisplay
                                label="Status"
                                value={
                                    <div className="SalesOrderDetailModal__StatusBadges">
                                        <SalesOrderStatusBadge status={model.context.status} />
                                        {model.context.isArchived && <ArchivedBadge />}
                                    </div>
                                }
                            />

                            <PropertyDisplay
                                label="Received"
                                value={formatDateTimeRelative(model.createdAt)}
                            />

                            <PropertyDisplay
                                label="ETA"
                                value={model.eta && formatDateRelative(model.eta)}
                            />

                            <div className="PropertyContainerSpacer" />

                            <PropertyDisplay
                                label="Shipping method"
                                value={model.context.shippingMethod?.name}
                            />

                            <PropertyDisplay
                                label="Shipping address"
                                value={model.context.shippingAddress?.fullAddress}
                            />

                            <PropertyDisplay
                                label="Shipping instructions"
                                value={model.shippingInstructions}
                            />

                            <PropertyDisplay
                                label="Tracking information"
                                value={model.trackingInformation}
                            />

                            <div className="PropertyContainerSpacer" />

                            <PropertyDisplay
                                label="Total sell price"
                                value={formatCurrency(model.totalSellPrice)}
                            />

                            <PropertyDisplay
                                label="Total tax"
                                value={formatCurrency(model.totalTax)}
                            />
                            <PropertyDisplay
                                label="Total freight"
                                value={
                                    <>
                                        {model.totalFreightOverride !== null && (
                                            <>
                                                <s className="SalesOrderDetailModal__OrginalFreight">
                                                    {formatCurrency(model.totalFreight)}
                                                </s>{' '}
                                            </>
                                        )}
                                        {formatCurrency(
                                            model.totalFreightOverride ?? model.totalFreight,
                                        )}
                                    </>
                                }
                            />

                            <div className="PropertyContainerSpacer" />
                            {model.context.customerPurchaseOrder.notes && (
                                <PropertyDisplay
                                    label="Customer notes"
                                    verticalAlign="top"
                                    value={
                                        <SafeHTML
                                            className="SalesOrderDetailModal__NotesHtml"
                                            html={model.context.customerPurchaseOrder.notes}
                                        />
                                    }
                                />
                            )}

                            <PropertyDisplay
                                label="Internal notes"
                                value={model.notes}
                                verticalAlign="top"
                            />

                            <PropertyDisplay
                                label="External notes"
                                value={model.customerNotes}
                                verticalAlign="top"
                            />
                        </PropertyContainer>
                    </div>
                    <Tabs
                        model={model}
                        documentCount={documentCount}
                        isAccountingEnabled={isAccountingEnabled}
                    />
                </>
            )}
        </MyModal>
    );
}

function Tabs({
    model,
    documentCount,
    isAccountingEnabled,
}: {
    model: SalesOrderDetail;
    documentCount: number | undefined;
    isAccountingEnabled: boolean;
}) {
    const [tab, setTab] = useUrlQueryState('tab', {
        allowedValues: ['items', 'attachments', 'accounting', 'history'],
    });

    const tabs = useMemo(
        () =>
            TabBuilder([
                {
                    name: 'items',
                    label: `Items (${model.context.orderLines.length ?? 0})`,
                    content: <OrderItemsTable order={model} />,
                },
                {
                    name: 'attachments',
                    label: documentCount ? `Attachments (${documentCount})` : 'Attachments',
                    content: (
                        <SalesOrderAttachmentsTab
                            key={model.id}
                            order={model}
                        />
                    ),
                },
                isAccountingEnabled && {
                    name: 'accounting',
                    label: 'Accounting',
                    content: (
                        <SalesOrderAccountingTab
                            key={model.id}
                            manufacturerOrderId={model.legacyId}
                        />
                    ),
                },
                {
                    name: 'history',
                    label: 'History',
                    content: (
                        <SalesOrderHistoryTab
                            key={model.id}
                            manufacturerOrderId={model.legacyId}
                        />
                    ),
                },
            ]),
        [documentCount, isAccountingEnabled, model],
    );

    return (
        <MyTabs
            className="SalesOrderDetailModal__Tabs"
            activeTab={tab}
            setActiveTab={setTab}
            tabs={tabs}
        />
    );
}

function OrderItemsTable({ order }: { order: SalesOrderDetail }) {
    const [searchParams] = useSearchParams();
    const { itemId: highlightedItemId } = useParams();

    const workItemsQuery = salesApi.useSalesOrderWorkItemsQuery(order.legacyId);
    const [selectedItems, setSelectedItems] = useState<SalesItem[]>([]);

    const columns = useMemo(
        () =>
            ColumnBuilder<SalesItem>()
                .column({
                    label: 'Item no',
                    key: 'lineNumber',
                    isSortable: true,
                    width: '1px',
                    whiteSpace: 'nowrap',
                    getValue: item => item.lineNumber,
                })
                .column({
                    label: 'Qty',
                    key: 'quantity',
                    isSortable: true,
                    align: 'center',
                    emptyDash: true,
                    getValue: item =>
                        item.context.product.hasQuantity ? item.quantity : undefined,
                })
                .column({
                    label: 'Description',
                    key: 'description',
                    isSortable: true,
                    getValue: item => item.context.product.name,
                })
                .column({
                    label: 'Status',
                    key: 'status',
                    isSortable: true,
                    getValue: item => item.orderLineStatusId,
                    renderValue: val => (
                        <SalesItemStatusBadge
                            statusId={val}
                            size="small"
                        />
                    ),
                })
                .column({
                    label: 'Work items',
                    key: 'workItems',
                    whiteSpace: 'nowrap',
                    render: item => (
                        <WorkItemsCell
                            workItems={workItemsQuery.data?.[item.id]}
                            salesItem={item}
                        />
                    ),
                })
                .build(),
        [workItemsQuery.data],
    );

    const dialogManager = useDialogManager();
    const handleBulkAssignStatus = useCallback(() => {
        dialogManager.custom(SalesItemBulkAssignStatusDialog, {
            items: selectedItems,
        });
    }, [dialogManager, selectedItems]);

    const handleSplitOrder = useCallback(() => {
        dialogManager.custom(SalesOrderSplitDialog, {
            order,
            selectedItems,
        });
    }, [dialogManager, order, selectedItems]);

    return (
        <>
            <div
                className={coalesceClassNames(
                    'SalesOrderDetailModal__ItemsToolbar',
                    selectedItems.length ? 'expanded' : 'collapsed',
                )}
            >
                <MyMenuButton
                    className="SalesOrderDetailModal__ItemsToolbar__BulkActionMenuButton"
                    buttonLabel={
                        <>
                            {selectedItems.length} {selectedItems.length === 1 ? 'row' : 'rows'}{' '}
                            selected
                        </>
                    }
                    buttonType="Secondary"
                    IconRight={Icons.CaretDown}
                    menuItems={[
                        {
                            label: 'Assign status',
                            IconLeft: Icons.ArrowNext,
                            onClick: handleBulkAssignStatus,
                        },
                        {
                            label: 'Split to new order',
                            IconLeft: Icons.OrderSplit,
                            onClick: handleSplitOrder,
                        },
                    ]}
                />
            </div>

            <DataTable
                // careful! this classname is referenced by javascript in SalesItemDrawer
                className="SalesOrderDetailModal__ItemsTable"
                data={order.context.orderLines}
                rowEndIcon={<Icons.ChevronRight />}
                rowLinkTo={item => `items/${item.id}?${searchParams}`}
                rowIsHighlighted={item => highlightedItemId === String(item.id)}
                columns={columns}
                zebra={true}
                canSelectRows={true}
                onRowSelectChanged={setSelectedItems}
            />
        </>
    );
}

function WorkItemsCell({
    workItems,
    salesItem,
}: {
    workItems?: SalesOrderWorkItem[];
    salesItem: SalesItem;
}) {
    const navigate = useNavigate();

    if (!workItems?.length) {
        return null;
    }

    return (
        <div
            className="SalesOrderDetailModal__WorkItemsCell"
            onClick={e => {
                e.preventDefault();
                navigate(`items/${salesItem.id}?itemTab=work`);
            }}
        >
            <WorkItemsProgress
                workItems={workItems}
                showPercentage
            />
        </div>
    );
}

function Sidebar({ model }: { model: SalesOrder }) {
    const dialogManager = useDialogManager();
    const [generateLabelMutation] = salesApi.useSalesOrderGenerateLabelsMutation();
    const [salesOrderWriteLogMutation] = salesApi.useSalesOrderWriteLogMutation();
    const [generateWorksheetsMutation] = salesApi.useSalesOrderGenerateWorksheetsMutation();
    const [labelConfigQuery] = salesApi.useLazySalesOrderLabelConfigQuery();
    const [generateConfirmationQuery] = salesApi.useLazySalesOrderGenerateConfirmationQuery();
    const [generatePackingSlipQuery] = salesApi.useLazySalesOrderGeneratePackingSlipQuery();

    const printSummaries = useCallback(async () => {
        if (model) {
            await dialogManager.showLoadingWhile(
                salesOrderWriteLogMutation({
                    manufacturerOrderId: model.legacyId,
                    manufacturerReference: model.manufacturerReference,
                    action: 'manufacturer_reference',
                    download: true,
                    downloadType: 'sales order',
                }),
            );
            window.open(
                `${Env.API_BASE_URL}/old/manufacturer-orders/generate-sales-order/${model?.legacyId}`,
                '_blank',
            );
        }
    }, [dialogManager, model, salesOrderWriteLogMutation]);

    const printLabels = useCallback(async () => {
        if (model) {
            const config = await dialogManager.showLoadingWhile(
                labelConfigQuery(model.legacyId).unwrap(),
            );
            const labelPrintResult: LabelPrintDialogResult = await dialogManager.custom(
                LabelPrintDialog,
                {
                    config,
                },
            );
            if (!labelPrintResult.confirmed) {
                // user cancelled
                return;
            }

            // write to log
            await dialogManager.showLoadingWhile(
                salesOrderWriteLogMutation({
                    manufacturerOrderId: model.legacyId,
                    manufacturerReference: model.manufacturerReference,
                    action: 'manufacturer_reference',
                    download: true,
                    downloadType: 'order label',
                }),
            );

            // generate labels
            try {
                const documentUrl = await dialogManager.showLoadingWhile(
                    generateLabelMutation({
                        order: model,
                        labelIds: labelPrintResult.labelIds,
                        copies: labelPrintResult.copies,
                    }).unwrap(),
                );
                window.open(documentUrl, '_blank');
            } catch (e) {
                dialogManager.alert({
                    title: 'Failed to generate labels',
                    message: 'Please check your label configs before trying again.',
                });
            }
        }
    }, [model, dialogManager, labelConfigQuery, salesOrderWriteLogMutation, generateLabelMutation]);

    /** Print worksheets - write to log and show a list of xls to download */
    const printWorksheets = useCallback(async () => {
        if (model) {
            // write to log
            await dialogManager.showLoadingWhile(
                salesOrderWriteLogMutation({
                    manufacturerOrderId: model.legacyId,
                    manufacturerReference: model.manufacturerReference,
                    action: 'manufacturer_reference',
                    download: true,
                    downloadType: 'order worksheet',
                }),
            );

            // generate worksheets
            const promise = generateWorksheetsMutation(model).unwrap();
            const result = await dialogManager.showLoadingWhile(promise);

            // show dialog
            await dialogManager.custom(SalesOrderWorksheetsDialog, {
                data: result,
                titleContext: `Sales Order ${model.manufacturerReference}`,
            });
        }
    }, [dialogManager, generateWorksheetsMutation, model, salesOrderWriteLogMutation]);

    const generateConfirmationPdf = useCallback(async () => {
        if (model) {
            const url = await dialogManager.showLoadingWhile(
                generateConfirmationQuery(model.id).unwrap(),
            );
            if (url) {
                window.open(url, '_blank');
            } else {
                dialogManager.alert({
                    title: 'Unable to generate confirmation PDF',
                    message:
                        'This has not been configured yet, please contact Quoterite for assistance.',
                });
            }
        }
    }, [dialogManager, generateConfirmationQuery, model]);

    const generatePackingSlip = useCallback(async () => {
        if (model) {
            const url = await dialogManager.showLoadingWhile(
                generatePackingSlipQuery(model.id).unwrap(),
            );
            if (url) {
                window.open(url, '_blank');
            } else {
                dialogManager.alert({
                    title: 'Unable to generate packing slip',
                    message:
                        'This has not been configured yet, please contact Quoterite for assistance.',
                });
            }
        }
    }, [dialogManager, generatePackingSlipQuery, model]);

    return (
        <div className="SalesOrderDetailModal__Sidebar">
            <PropertyContainer>
                <PropertyDisplay
                    label="Order group"
                    value={
                        <>
                            <Link
                                className="Link"
                                to={`/sales?search=${model.context.customerOrderGroup.uniqueId}`}
                            >
                                {model.context.customerOrderGroup.uniqueId}
                            </Link>{' '}
                        </>
                    }
                />

                <PropertyDisplay
                    label="Sidemark"
                    value={model.context.customerPurchaseOrder.sidemark}
                />

                <PropertyDisplay
                    label="Customer"
                    inlineHint={model.context.customerConfig.isInternal && '(external)'}
                    value={getCustomerDisplayName(model)}
                />

                {/* If dealer is an internal customer then display the internal customer details here */}
                {model.context.customerConfig.isInternal && (
                    <PropertyDisplay
                        label="Internal customer"
                        value={model.context.mainCompany.name}
                    />
                )}

                <hr />

                {/* Actions */}
                <PropertyDisplay
                    label="Production documents"
                    value={
                        <ul className="SalesOrderDetailModal__Sidebar__Links">
                            <li>
                                <MyButton
                                    label="Summary"
                                    buttonType="LinkButton"
                                    onClick={printSummaries}
                                />
                            </li>
                            <li>
                                <MyButton
                                    label="Labels"
                                    buttonType="LinkButton"
                                    onClick={printLabels}
                                />
                            </li>
                            <li>
                                <MyButton
                                    label="Worksheets"
                                    buttonType="LinkButton"
                                    onClick={printWorksheets}
                                />
                            </li>
                        </ul>
                    }
                />
                <PropertyDisplay
                    label="Order documents"
                    value={
                        <>
                            <ul className="SalesOrderDetailModal__Sidebar__Links">
                                <li>
                                    <MyButton
                                        label="Order confirmation"
                                        buttonType="LinkButton"
                                        onClick={generateConfirmationPdf}
                                    />
                                </li>
                                <li>
                                    <MyButton
                                        label="Packing slip"
                                        buttonType="LinkButton"
                                        onClick={generatePackingSlip}
                                    />
                                </li>
                            </ul>
                        </>
                    }
                />
            </PropertyContainer>
        </div>
    );
}
